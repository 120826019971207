import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import axiosInstance from '../utils/axiosInstance';
import './admin.css';


function Admin( {authenticatedUser, isAdmin} ) {  

    const getInitialCurrentUser = () => {
        const value = "ALL USERS"
        return value;
    }
    const getInitialSortByState = () => {
        const value = "Date";
        return value;
    };
    const getInitialSortDirState = () => {
        const value = "DESC";
        return value;
    };

    const navigate = useNavigate();

    const [users, setUsers] = useState([]);
    const [currentUser, setCurrentUser] = useState(getInitialCurrentUser)

    const [columnNames, setColumnNames] = useState(["Date", "A-Z", "Total Refund", "LowPro"]);
    const [sortBy, setSortBy] = useState(getInitialSortByState);
    
    const [sortOpt, setSortOpt] = useState(["DESC", "ASC"]);
    const [sortDir, setSortDir] = useState(getInitialSortDirState);

    const [activity, setActivity] = useState([]);

    

    useEffect(() => {
        if((authenticatedUser === null || authenticatedUser === "")){
            navigate("/login");
            if(isAdmin === false){
                navigate("/search");
            }
        }

        clearSearch();
    },[]);

    const clearSearch = () => {
        setCurrentUser(getInitialCurrentUser);
        setSortBy(getInitialSortByState);
        axiosInstance.get('/getActivity', {withCredentials:true})
        .then(response => {
            setActivity(response.data[0]);
            setUsers(response.data[1]);
        })
        .catch(error => console.error('Error fetching activity:', error));
    };

    const getUserActivity = () => {
        let tempUser
        let tempID
        if(currentUser !== 'ALL USERS'){
            tempUser = users.find(i => i.username === currentUser)
            tempID = tempUser.ID
            console.log("tempID: ", tempID)
        }
        else {
            tempID = 0
        }
        console.log("tempID: ", tempID)
        axiosInstance.post('/searchActivity/', {data: {id: tempID, sortBy: sortBy, sortDir: sortDir}}, {withCredentials:true})
        .then(response => {
            setActivity(response.data[0]);
        })
        .catch(error => console.error('Error fetching search activity:', error));
    };

    const handleUserChange = (e) => {
        setCurrentUser(e.target.value);
    };

    const handleSortByDropDownChange = (e) => {
        setSortBy(e.target.value);
    };

    const handleSortDirDropDownChange = (e) => {
        setSortDir(e.target.value);
    }

    const clickMe = (data) => {
        navigate("/taxpayer", { state: {data: data} });
    }

    return (
        <div className="admin-app">
                
            <h1>Admin Page</h1>
            <p>Disclaimer: All information contained herein is proprietary to T-COT and is provided solely for the purpose of obtaining a contract with T-COT. Unauthorized use, disclosure, or distribution of this information is strictly prohibited. Any other use of this information without the express written consent of T-COT is not permitted.</p>

            <div className="admin-input">
                <a>Filter By: </a>

                <select currentUser={currentUser} onChange={handleUserChange}>
                    <option value="ALL USERS">ALL USERS</option>
                    {users.map((userObj, i) => (
                        <option key={i} value={userObj.username}>{userObj.username}</option>
                    ))}
                </select>
                
                <a>Sort By: </a>

                <select value={sortBy} onChange={handleSortByDropDownChange}>
                    {columnNames.map((columnName, i) => (
                        <option key={i} value={columnName}>{columnName}</option>
                    ))}
                </select>

                <select value={sortDir} onChange={handleSortDirDropDownChange}>
                    {sortOpt.map((sortOption, i) => (
                        <option key={i} value={sortOption}>{sortOption}</option>
                    ))}
                </select>

                <button onClick={getUserActivity}>Search</button>
                
                <button onClick={clearSearch}>Clear Search</button>

            </div>

            {activity.length === 0 && <h1>No Results Found</h1>}

            {activity.length > 0 && (
            <table className="activity-table">
                <tbody>
                    <tr>
                        <th width="716.48px">TaxpayerName</th>
                        <th width="260.17px">Area</th>
                        <th width="122.34px">LowPro Refund</th>
                        <th width="122.34px">Total Refund</th>
                        <th>Username</th>
                        <th>Date and Time</th>
                        <th>Length of Time</th>
                    </tr>
                    
                    {activity.map((activityObj, i) => (
                        <tr height="58px" key={i}>
                            <td>
                                <button onClick={() => clickMe({TPNumber: activityObj.TaxpayerNumber})} className="ViewDetailsBtnLink">{activityObj.TaxpayerName}</button>
                            </td>
                            <td>
                                {activityObj.Area}
                            </td>
                            <td>
                                {activityObj.LowPro}
                            </td>
                            <td>
                                {activityObj.Refund}
                            </td>
                            <td>
                                {activityObj.username}
                            </td>
                            <td>
                                {activityObj.dateSearched}
                            </td>
                            <td>
                                {null}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>)}

        </div>
    );
}

export default Admin;