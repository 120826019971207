import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import axiosInstance from '../utils/axiosInstance';
import './taxpayer.css';

function Taxpayer( {authenticatedUser} ) {  
  const navigate = useNavigate();
  const location = useLocation();

  const [tpName, setTPName] = useState('');
  const [lowpro, setLowPro] = useState('0.00');
  const [mktcosts, setMktCosts] = useState('0.00');
  const [highCost, setHighCost] = useState('0.00');
  const [address, setAddress] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [officers, setOfficers] = useState([]);
  const [showOfficers, setShowOfficers] = useState(false);
  const [lastUpdatedDate, setLastUpdatedDate] = useState('');
  const [drillingPermits, setDrillingPermits] = useState('');
  const [oilDict, setOilDict] = useState({_2024:null, _2023:null, _2022:null, _2021:null});
  const [gasDict, setGasDict] = useState({_2024:null, _2023:null, _2022:null, _2021:null});
  const [clientInfo, setClientInfo] = useState({clientStatus:'N/A', auditor:null, note:null});

  const [tempTP, setTempTP] = useState('');

  if(authenticatedUser === null || authenticatedUser === ""){
    navigate("/login");
  }

  useEffect(() => {
    
    if(!location.state)
      setTempTP('32054404077')
    else {
      setTempTP(location.state.data.TPNumber)
    }

    axiosInstance.get('/lowpro/' + tempTP, {withCredentials:true})
    .then(response => {
      console.log(response.data)
      setTPName(response.data[0][0].TaxpayerName)

      if(response.data[1][0] !== undefined){
        console.log('LowPro: ' + response.data[1][0])
        setLowPro(response.data[1][0].LowPro);
      }

      if(response.data[2][0] !== undefined)
        setMktCosts(response.data[2][0].MktCosts);

      if(response.data[3][0] !== undefined)
        setAddress(response.data[3][0].Address);
      else
        setAddress("N/A");

      if(response.data[4][0] !== undefined)
        setPhoneNumber(response.data[4][0].Phone);
      else
        setPhoneNumber("N/A");

      if(response.data[5] !== undefined) {
        setOfficers(response.data[5])
      }
      else console.log("No officer data");

      setLastUpdatedDate(response.data[6][0].UpdatedDate);

      setDrillingPermits(response.data[7][0].NumOfPermits);
      
      if(response.data[8][0] !== undefined){
        setOilDict(prevDict => ({ ...prevDict, _2024: response.data[8][0]._2024 }));
        setOilDict(prevDict => ({ ...prevDict, _2023: response.data[8][0]._2023 }));
        setOilDict(prevDict => ({ ...prevDict, _2022: response.data[8][0]._2022 }));
        setOilDict(prevDict => ({ ...prevDict, _2021: response.data[8][0]._2021 }));
      }

      if(response.data[9][0] !== undefined){
        setGasDict(prevDict => ({ ...prevDict, _2024: response.data[9][0]._2024 }));
        setGasDict(prevDict => ({ ...prevDict, _2023: response.data[9][0]._2023 }));
        setGasDict(prevDict => ({ ...prevDict, _2022: response.data[9][0]._2022 }));
        setGasDict(prevDict => ({ ...prevDict, _2021: response.data[9][0]._2021 }));
      }

      if(response.data[10][0] !== undefined){
        setClientInfo(prevClient => ({ ...prevClient, clientStatus: response.data[10][0]['ClientStatus']}));
        setClientInfo(prevClient => ({ ...prevClient, auditor: response.data[10][0]['Assigned To']})); 
        setClientInfo(prevClient => ({ ...prevClient, note: response.data[10][0]['MKT RPT NOTE']}));
      }
      else{
        console.log("No Client Info");
      }

      if(response.data[11][0] !== undefined)
        setHighCost(response.data[11][0].HighCost)

    })
    .catch(error => console.error('Error fetching lowpros:', error));
  }, [location, tempTP]);

  const changeOfficers = () => {
    if(!showOfficers)
      setShowOfficers(true);
    else
      setShowOfficers(false);
  }


  return (
    <div className="taxpayer-app">
      
      <div className="taxpayer-header">
        <h1>Taxpayer Summary</h1>
        <p>Disclaimer: All information contained herein is proprietary to T-COT and is provided solely for the purpose of obtaining a contract with T-COT. Unauthorized use, disclosure, or distribution of this information is strictly prohibited. Any other use of this information without the express written consent of T-COT is not permitted.</p>
      </div>
      
      <div className="taxpayer-all">

        <div className="taxpayer-info">

          <ul className="info-list">
            <p> {tpName} </p>
            <li key={"taxpayerNumber"}>
              {"Taxpayer Number: " + tempTP}
            </li>
            <li>
              {"Updated: " + lastUpdatedDate}
            </li>

            {(clientInfo['clientStatus'] === 'N/A' && !clientInfo['auditor'] && !clientInfo['note']) && 
            <li key={"clientStatus"}>
              {"Client Status: " + clientInfo['clientStatus']}
            </li>}
            
            {(clientInfo['clientStatus'] !== 'N/A' || clientInfo['auditor'] !== null || clientInfo['note'] !== null) && 
            <li key={"clientStatus"}>
              {"Client Status: " + clientInfo['clientStatus']}
            </li>}

            {(clientInfo['clientStatus'] !== 'N/A' || clientInfo['auditor'] !== null || clientInfo['note'] !== null) && 
            <li key={"auditorAssigned"}>
              {"Assigned to: " + clientInfo['auditor']}
            </li>}
            
            {clientInfo['note'] !== null &&
              <li key={"auditorAssigned"}>
                {"Client Note:"}&nbsp;<strong>{clientInfo['note']}</strong>
              </li>
            }


            <li key={"address"}>
              {"Address: " + address}
            </li>
            <li key={"phoneNumber"}>
              {"Phone Number: " + phoneNumber}
            </li>
          </ul>
          
        </div>

        <div className='middle-div'></div>

        <div className="refund-info">
          
          <ul className="refund-list">
            <p>Refund Estimates</p>
            <li key={"lowpro"}>
              {"Low Production: " + lowpro}
            </li>
            <li key={"marketingCosts"}>
              {"Marketing Costs: " + mktcosts}
            </li>
            <li key={"highCost"}>
              {"High Cost: " + highCost}
            </li>
            <li key={"gor"}>
              {"GOR: " + null}
            </li>
            <li key={"twoYearInactive"}>
              {"Two Year Inactive: " + null}
            </li>
            <li key={"flare"}>
              {"Flare: " + null}
            </li>
          </ul>
        </div>

        <div className='middle-div2'></div>

        <div className="well-info">
          <ul className = "wellInfo">

            <p>Well Information</p>
            
            <li key={"newDP"}>
              {"New Drill Permits: " + drillingPermits}
            </li>

            <li key={"oilWells"}>
              {"Operated Oil Wells: "}
            </li>
            <li key={"oil2024"}>
              {"2024: " + oilDict['_2024']}
            </li>
            <li key={"oil2023"}>
              {"2023: " + oilDict['_2023']}
            </li>
            <li key={"oil2022"}>
              {"2022: " + oilDict['_2022']}
            </li>
            <li key={"oil2021"}>
              {"2021: " + oilDict['_2021']}
            </li>

            <li key={"gasWells"}>
              {"Operated Gas Wells: "}
            </li>
            <li key={"gas2024"}>
              {"2024: " + gasDict['_2024']}
            </li>
            <li key={"gas2023"}>
              {"2023: " + gasDict['_2023']}
            </li>
            <li key={"gas2022"}>
              {"2022: " + gasDict['_2022']}
            </li>
            <li key={"gas2021"}>
              {"2021: " + gasDict['_2021']}
            </li>
          </ul>
          
        </div>
        
        <div className='middle-div3'></div>
        
        <div className="officer-info">
          <ul className='officer-list'>
            <li>
              {!showOfficers && <button onClick={changeOfficers}>Show Company Officers</button>}
              {showOfficers && <button onClick={changeOfficers}>Hide Company Officers</button>}
            </li>
            {showOfficers && officers.map((officerObj, i) => (
              <li>{officerObj.Title}: {officerObj.Name}</li>
            ))}
          </ul>
        </div>
      </div>

    </div>
  );
}

export default Taxpayer;